import React, { useState } from "react";
import styled from "styled-components";
import { Row, Col } from "styled-bootstrap-grid";

// Styles
import { secondaryDark, primaryLight, secondaryLight } from "../utils/colors";

// Components
import { FlexContainer } from "../containers/flex-container";

const Textbox = styled.div`
	columns: ${(props) => props.textColumns};
	column-gap: 30px;

	& p:first-of-type {
		margin-top: 0;
	}

	& p:last-of-type {
		margin-bottom: 0;
	}

	@media (max-width: 1024px) {
		columns: unset;
		column-gap: unset;
	}

	&.faq-section-title {
		margin: 0 0 20px 0;
		padding: 0 0 20px 0;

		border-bottom: 1px solid ${secondaryDark};
	}
`;

const SingleFaq = styled.div`
	margin: 0 0 20px 0;
	padding: 0 0 20px 0;
	border-bottom: 1px solid ${secondaryDark};

	&:last-of-type {
		margin: 0;
		padding: 0;
		border-bottom: 1px solid transparent;
	}

	& button {
		width: 100%;
		text-align: left;

		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
	}

	& .faq-text {
		display: ${(props) => props.display};
		margin: 20px 0 0 0;
	}
`;

export const Faqs = ({ content, pageType }) => {
	const [isFaqOpen, setIsFaqOpen] = useState(null);

	const allFaqs = content.items.map((content, index) => {
		const faqId = `single_faq_${index}_${content.id}`;
		return (
			<SingleFaq key={faqId} display={isFaqOpen === faqId ? `block` : `none`}>
				<button
					onClick={
						isFaqOpen === faqId
							? () => setIsFaqOpen(null)
							: () => setIsFaqOpen(faqId)
					}
				>
					<h2>{content.faq_title.text}</h2>
					<span>{isFaqOpen === faqId ? `–` : `+`}</span>
				</button>
				<Textbox
					className="faq-text"
					dangerouslySetInnerHTML={{
						__html: content.faq_text.html,
					}}
				/>
			</SingleFaq>
		);
	});

	return (
		<FlexContainer
			desktopPadding={pageType === `collection` ? `0 60px 0 135px` : `0 60px`}
			tabletPadding={pageType === `collection` ? `0 35px 0 135px` : `0 35px`}
			mobilePadding={`0 15px`}
			cv={true}
		>
			<Row justifyContent={`center`}>
				<Col col={12} md={8}>
					<Textbox
						className="faq-section-title"
						dangerouslySetInnerHTML={{
							__html: content.primary.faq_section_title.html,
						}}
					/>
				</Col>
				<Col col={12} md={8}>
					{allFaqs}
				</Col>
			</Row>
		</FlexContainer>
	);
};
