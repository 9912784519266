import React from "react";
import styled from "styled-components";
import { Row, Col } from "styled-bootstrap-grid";

// Transitions
import { TransitionContainer } from "../containers/transition-container";

// Components
import { FlexContainer } from "../containers/flex-container";
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";

const ImageWithTextContainer = styled.div`
	width: 100%;
`;

const Textbox = styled.div`
	max-width: 500px;
	margin: ${(props) => props.margin};

	& p:first-of-type {
		margin-top: 0;
	}

	& p:last-of-type {
		margin-bottom: 0;
	}

	@media (max-width: 768px) {
		margin: ${(props) => (props.mobileMargin ? props.mobileMargin : `0`)};
	}
`;

const ImageCaption = styled.div``;

export const ImageWithText = ({ content, pageType, contentIndex }) => {
	if (content.primary.image_with_text_image.fluid !== null) {
		const isImageLandscape =
			content.primary.image_with_text_image.dimensions.width >
			content.primary.image_with_text_image.dimensions.height;

		let verticalAlign;
		if (
			content.primary.text_vertical_position === null ||
			content.primary.text_vertical_position === `Bottom`
		) {
			verticalAlign = `end`;
		} else if (content.primary.text_vertical_position === `Middle`) {
			verticalAlign = `center`;
		} else if (content.primary.text_vertical_position === `Top`) {
			verticalAlign = `start`;
		}
		return (
			<FlexContainer
				desktopPadding={pageType === `collection` ? `0 60px 0 135px` : `0 60px`}
				tabletPadding={pageType === `collection` ? `0 35px 0 135px` : `0 35px`}
				mobilePadding={`0 15px`}
				cv={true}
			>
				<TransitionContainer>
					<ImageWithTextContainer>
						<Row justifyContent="center" alignItems={verticalAlign}>
							<Col
								col={12}
								md={6}
								lg={5}
								order={2}
								mdOrder={content.primary.text_position === "Left" ? 1 : 12}
							>
								<Textbox
									className="title"
									dangerouslySetInnerHTML={{
										__html: content.primary.image_with_text_title.html,
									}}
									margin={
										content.primary.text_position === "Left"
											? `0 0 10px auto`
											: `0 auto 10px 0`
									}
									mobileMargin={`20px 0 10px 0`}
								/>
								<Textbox
									className="text"
									dangerouslySetInnerHTML={{
										__html: content.primary.image_with_text.html,
									}}
									margin={
										content.primary.text_position === "Left"
											? `0 0 0 auto`
											: `0 auto 0 0`
									}
								/>
							</Col>
							<Col
								col={12}
								md={6}
								lg={isImageLandscape === true ? 7 : 5}
								order={1}
								mdOrder={content.primary.text_position === "Left" ? 12 : 1}
							>
								{content.primary.image_with_text_image.fluid !== null && (
									<>
										<AspectRatioImageContainer
											image={content.primary.image_with_text_image}
										>
											<img
												srcSet={
													content.primary.image_with_text_image.fluid.srcSetWebp
												}
												src={
													content.primary.image_with_text_image.fluid.srcWebp
												}
												alt={content.primary.image_with_text_image.alt}
												loading={`lazy`}
											/>
										</AspectRatioImageContainer>
										{content.primary.image_credit !== undefined && (
											<>
												{content.primary.image_credit.text !== "" && (
													<ImageCaption
														className="small-text"
														dangerouslySetInnerHTML={{
															__html: content.primary.image_credit.html,
														}}
													/>
												)}
											</>
										)}
									</>
								)}
							</Col>
						</Row>
					</ImageWithTextContainer>
				</TransitionContainer>
			</FlexContainer>
		);
	} else {
		return null;
	}
};
