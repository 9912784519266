import React from "react";
import styled from "styled-components";

// Transitions
import { TransitionContainer } from "../containers/transition-container";

// Components
import { FlexContainer } from "../containers/flex-container";

const Grid = styled.div`
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-gap: 30px;

	@media (max-width: 767px) {
		display: block;
	}
`;

const Textbox = styled.div`
	max-width: 570px;
	grid-column: 4 / 13;

	& p:first-of-type {
		margin-top: 0;
	}

	& p:last-of-type {
		margin-bottom: 0;
	}

	& p:empty {
		display: inline-block;
	}

	@media (max-width: 767px) {
		margin: 0 auto;

		grid-column: unset;
	}
`;

export const ArticleText = ({ content, textWidth, pageType }) => {
	return (
		<FlexContainer
			desktopPadding={`0 60px`}
			tabletPadding={`0 35px`}
			mobilePadding={`0 15px`}
			cv={true}
		>
			<TransitionContainer>
				<Grid>
					<Textbox
						dangerouslySetInnerHTML={{
							__html: content.primary.text.html,
						}}
					/>
				</Grid>
			</TransitionContainer>
		</FlexContainer>
	);
};
