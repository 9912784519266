import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

// Components
import { FlexContainer } from "../containers/flex-container";
import { SingleCollectionFilters } from "../navigation/single-collection-filters";
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";

// Colors
import { secondaryDark } from "../utils/colors";

const Product = styled.div`
	opacity: ${(props) => props.opacity};
	transition: 150ms opacity ease;

	& p {
		margin: 10px 0 40px 0;
		opacity: ${(props) => props.titleOpacity};

		color: ${secondaryDark};
	}

	&:hover {
		opacity: 0.85;
		transition: 150ms opacity ease;

		& p {
			opacity: 1;
		}
	}

	& img {
		object-fit: cover;

		width: 100%;
		height: 100%;
	}
`;

const ProductRow = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;

	@media (max-width: 768px) {
		margin-right: -7.5px;
		margin-left: -7.5px;
	}
`;

const ProductColumn = styled.div`
	position: relative;
	width: 100%;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;

	flex: 0 0 20%;
	max-width: 20%;

	@media (max-width: 1260px) {
		flex: 0 0 25%;
		max-width: 25%;
	}

	@media (max-width: 1000px) {
		flex: 0 0 33.333%;
		max-width: 33.333%;
	}

	@media (max-width: 768px) {
		padding-right: 7.5px;
		padding-left: 7.5px;
	}

	@media (max-width: 700px) {
		flex: 0 0 50%;
		max-width: 50%;
	}
`;

export const CollectionProducts = ({
	content,
	pageType,
	activeFilter,
	setActiveFilter,
}) => {
	const products = content.items.map((item, index) => {
		return (
			<ProductColumn
				key={`single_related_product_${item.product.document.id}_${index}`}
				// style={
				// 	item.product.document.data.linked_product.product_type ===
				// 		activeFilter || activeFilter === "All"
				// 		? { display: `block` }
				// 		: { display: `none` }
				// }
				className={
					item.product.document.data.linked_product.product_type ===
						activeFilter || activeFilter === "All"
						? `visible`
						: `hidden`
				}
			>
				<Link
					to={`/products/${item.product.document.data.linked_product.handle}`}
				>
					<Product
						className="product"
						opacity={
							item.product.document.data.linked_product.product_type ===
								activeFilter || activeFilter === "All"
								? 1
								: 0.2
						}
						titleOpacity={
							item.product.document.data.linked_product.product_type ===
								activeFilter || activeFilter === "All"
								? 1
								: 0
						}
					>
						{item.product.document.data.thumbnail.fluid !== null && (
							<AspectRatioImageContainer image={null} padding={75}>
								<img
									srcSet={item.product.document.data.thumbnail.fluid.srcSetWebp}
									src={item.product.document.data.thumbnail.fluid.srcWebp}
									alt={item.product.document.data.thumbnail.alt}
									loading={`lazy`}
								/>
							</AspectRatioImageContainer>
						)}

						<p>{item.product.document.data.linked_product.title}</p>
					</Product>
				</Link>
			</ProductColumn>
		);
	});

	return (
		<>
			<SingleCollectionFilters
				activeFilter={activeFilter}
				setActiveFilter={setActiveFilter}
				products={content.items}
			/>
			<FlexContainer
				largeDesktopPadding={
					pageType === `collection` ? `100px 135px 0 135px` : `0 135px`
				}
				desktopPadding={
					pageType === `collection` ? `100px 60px 0 135px` : `0 60px`
				}
				tabletPadding={
					pageType === `collection` ? `100px 35px 0 135px` : `0 35px`
				}
				mobilePadding={`0 15px`}
				id="collection-products"
				cv={true}
			>
				<ProductRow>{products}</ProductRow>
			</FlexContainer>
		</>
	);
};
