import React, { useState } from "react";
import styled from "styled-components";
import { Row, Col } from "styled-bootstrap-grid";
import { Carousel } from "react-responsive-carousel";
import "../styles/gallery.css";

// Components
import { FlexContainer } from "../containers/flex-container";

const GalleryContainer = styled.div`
	position: relative;

	width: 100%;
	height: 100%;
	max-height: 100%;

	padding: 0;

	& .carousel.carousel-slider,
	& .slider-wrapper,
	& .image-gallery,
	& .slider,
	& .slide,
	& video,
	& img {
		height: 100%;
		max-height: 100%;
	}

	& .carousel.carousel-slider .control-arrow {
		padding: 0;
		width: 50%;
	}
`;

const ImageContainer = styled.div`
	position: relative;

	width: 100%;
	height: 100%;

	overflow: hidden;

	& a {
		display: block;

		width: 100%;
		height: 100%;

		position: relative;
	}

	& img {
		max-width: 100%;

		object-fit: contain;
	}
`;

const Caption = styled.div`
	position: relative;

	width: 100%;

	margin: 20px auto 0 auto;

	& p {
		margin: 0;
	}
`;

const Textbox = styled.div`
	margin: ${(props) => props.margin};

	& p:first-of-type {
		margin-top: 0;
	}

	& p:last-of-type {
		margin-bottom: 0;
	}

	@media (max-width: 768px) {
		margin: ${(props) => (props.mobileMargin ? props.mobileMargin : `0`)};
	}
`;

export const Gallery = ({ content }) => {
	const [currentSlide, setCurrentSlide] = useState(0);
	const [activeCaption, setActiveCaption] = useState(
		content.items[0].text.html
	);

	const updateCurrentSlide = (index) => {
		setActiveCaption(content.items[index].text.html);

		if (currentSlide !== index) {
			setCurrentSlide(index);
		}
	};

	const galleryContent = content.items
		.filter((content) => content.image.fluid !== null)
		.map((content, index) => {
			return (
				<ImageContainer key={`project_images_${index}`}>
					<img
						src={content.image.fluid.srcWebp}
						srcSet={content.image.fluid.srcSetWebp}
						alt={content.image.alt}
						loading={index <= 2 ? `lazy` : `eager`}
					/>
				</ImageContainer>
			);
		});

	return (
		<FlexContainer
			desktopPadding={`0 60px`}
			tabletPadding={`0 35px`}
			mobilePadding={`0 15px`}
			cv={true}
		>
			<Row justifyContent="center">
				<Col col={12} md={10}>
					<Textbox
						margin={`0 0 10px auto`}
						mobileMargin={`0 0 10px 0`}
						dangerouslySetInnerHTML={{
							__html: content.primary.slideshow_title.html,
						}}
					/>
					<GalleryContainer>
						<Carousel
							showThumbs={false}
							showIndicators={false}
							showStatus={false}
							infiniteLoop={true}
							useKeyboardArrows={true}
							className="limited-edition-gallery"
							selectedItem={currentSlide}
							onChange={(index) => updateCurrentSlide(index)}
							autoPlay={false}
							stopOnHover={false}
							interval={3000}
							swipeable={true}
						>
							{galleryContent}
						</Carousel>

						<Caption
							dangerouslySetInnerHTML={{
								__html: activeCaption,
							}}
						/>
					</GalleryContainer>
				</Col>
			</Row>
		</FlexContainer>
	);
};
