import React from "react";
import { Link } from "gatsby";
import { Row, Col } from "styled-bootstrap-grid";
import styled from "styled-components";

// Components
import { FlexContainer } from "../containers/flex-container";

const SectionTitle = styled.div`
	margin: 0 0 30px 0;

	& h1 {
		@media (max-width: 1024px) {
			font-size: 20px;
		}
	}
`;

const CollectionTitle = styled.h2`
	margin: 23px 0 0 0;
`;

export const Collections = ({ content, pageType }) => {
	const collections = content.items.map((item, index) => (
		<Col col={6} key={`collection_item_${index}`}>
			<Link to={item.collection.document.url}>
				{item.collection.document.data.thumbnail.fluid !== null && (
					<img
						srcSet={item.collection.document.data.thumbnail.fluid.srcSetWebp}
						src={item.collection.document.data.thumbnail.fluid.srcWebp}
						alt={item.collection.document.data.thumbnail.alt}
						loading={`lazy`}
					/>
				)}
				<CollectionTitle className="large">
					{item.collection.document.data.title.text}
				</CollectionTitle>
			</Link>
		</Col>
	));

	return (
		<FlexContainer
			desktopPadding={pageType === `collection` ? `0 60px 0 135px` : `0 60px`}
			tabletPadding={pageType === `collection` ? `0 35px 0 135px` : `0 35px`}
			mobilePadding={`0 15px`}
			cv={true}
		>
			<Row>
				<Col col={12}>
					<SectionTitle
						dangerouslySetInnerHTML={{
							__html: content.primary.collections_title.html,
						}}
					/>
				</Col>

				<>{collections}</>
			</Row>
		</FlexContainer>
	);
};
