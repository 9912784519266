export const desktopContentSize = (imageSize) => {
	switch (imageSize) {
		case "Small":
			return 6;
		case "Medium":
			return 7;
		case "Large":
			return 9;
		case "Full Width":
			return 12;
		default:
			return null;
	}
};

export const desktopContentPosition = (imagePosition) => {
	switch (imagePosition) {
		case "Left":
			return `start`;
		case "Centre":
			return `center`;
		case "Right":
			return `end`;
		default:
			return null;
	}
};
