import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";
import "../styles/gallery.css";
import { Row, Col } from "styled-bootstrap-grid";

// Intersection Observer
import Observer from "@researchgate/react-intersection-observer";

// Context
import { MenuColorConsumer } from "../context/menu-color";

// Styles
import { secondaryDark, primaryLight, secondaryLight } from "../utils/colors";

// Transitions
import { TransitionContainer } from "../containers/transition-container";

// Hooks
import { useMediaQuery } from "../hooks/useMediaQuery";

const FlexWrapper = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;

	color: #fff;
	background-color: #000;

	width: 100%;
	height: 100%;
	max-height: 100%;
`;

const GalleryContainer = styled.div`
	position: relative;

	width: 100%;

	padding: 60px 15px 85px 15px;

	& .carousel.carousel-slider .control-arrow {
		padding: 0;
		width: 25%;
	}

	& h1 {
		margin: 0 0 25px 0;
	}
`;

const ImageContainer = styled.div`
	position: relative;

	width: 100%;
	height: 100%;

	overflow: hidden;

	& a {
		display: block;

		width: 100%;
		height: 100%;

		position: relative;
	}

	& img {
		max-width: 100%;

		object-fit: contain;
	}
`;

const Textbox = styled.div`
	width: 100%;

	& p:first-of-type {
		margin-top: 0;
	}

	& p:last-of-type {
		margin-bottom: 0;
	}
`;

const Caption = styled.div`
	position: relative;

	width: 100%;

	margin: 20px auto 0 auto;
	font-feature-settings: "tnum";
`;

const LimitedEditionGalleryContent = ({
	content,
	setMenuColor,
	contentIndex,
}) => {
	const [currentSlide, setCurrentSlide] = useState(0);
	const [allGalleryContent, setAllGalleryContent] = useState([]);
	const [totalSlides, setTotalSlides] = useState(null);

	// Media Query
	let isMobile = useMediaQuery("(hover: none) and (pointer: coarse)");

	const options = {
		onChange: (entry, unobserve) => onIntersectionChange(entry, unobserve),
		rootMargin: "0px 0px -100% 0px",
	};

	const onIntersectionChange = (entry, unobserve) => {
		if (entry.isIntersecting) {
			setMenuColor(`#000`);
		} else {
			setMenuColor(`#fff`);
		}
	};

	useEffect(() => {
		setTotalSlides(content.items.length);
		setAllGalleryContent(content.items);
	}, []);

	const updateCurrentSlide = (index) => {
		if (currentSlide !== index) {
			setCurrentSlide(index);
		}
	};

	const galleryContent = content.items
		.filter((content) => content.image.fluid !== null)
		.map((content, index) => (
			<ImageContainer key={`project_images_${index}`}>
				<Link to={content.product.url}>
					<img
						src={content.image.fluid.srcWebp}
						srcSet={content.image.fluid.srcSetWebp}
						alt={content.image.alt}
						loading={index === 0 ? `eager` : `lazy`}
					/>
				</Link>
			</ImageContainer>
		));

	return (
		<Observer {...options}>
			<FlexWrapper>
				<TransitionContainer>
					<GalleryContainer>
						<Row justifyContent="center">
							<Col col={11} md={10} lg={8} xl={8}>
								<Textbox
									dangerouslySetInnerHTML={{
										__html: content.primary.limited_edition_title.html,
									}}
								/>
								<Carousel
									showThumbs={false}
									showIndicators={false}
									showStatus={false}
									infiniteLoop={true}
									useKeyboardArrows={true}
									className="limited-edition-gallery"
									selectedItem={currentSlide}
									onChange={(index) => updateCurrentSlide(index)}
									autoPlay={isMobile ? false : true}
									stopOnHover={false}
									interval={10000}
									swipeable={true}
								>
									{galleryContent}
								</Carousel>
							</Col>
						</Row>
					</GalleryContainer>
				</TransitionContainer>
			</FlexWrapper>
		</Observer>
	);
};

export const LimitedEditionGallery = ({ content, contentIndex }) => (
	<MenuColorConsumer>
		{({ setMenuColor }) => {
			if (content.items !== undefined) {
				return (
					<LimitedEditionGalleryContent
						content={content}
						setMenuColor={setMenuColor}
						contentIndex={contentIndex}
					/>
				);
			} else {
				return null;
			}
		}}
	</MenuColorConsumer>
);
