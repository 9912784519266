import React from "react";
import styled from "styled-components";
import { Row, Col } from "styled-bootstrap-grid";

// Transitions
import { TransitionContainer } from "../containers/transition-container";

// Utils
import { desktopContentPosition } from "../utils/content-size-position";

// Components
import { FlexContainer } from "../containers/flex-container";

const Textbox = styled.div`
	columns: ${(props) => props.textColumns};
	column-gap: 30px;

	max-width: ${(props) => props.textWidth};
	margin: ${(props) => props.textMargin};

	& p:last-of-type {
		margin-bottom: 0;
	}

	& p:empty {
		display: inline-block;
		margin: 0;
	}

	&.two-column-text {
		& p:first-of-type {
			margin-top: 0;
		}
	}

	@media (max-width: 1024px) {
		columns: unset;
		column-gap: unset;

		max-width: 590px;

		${(props) => props.textColumns === 1 && `margin: 0;`}
		${(props) => props.textColumns === 2 && `margin: 0 auto;`}
	}

	@media (max-width: 700px) {
		margin: 0;
	}
`;

export const Text = ({ content, pageType }) => {
	const textColumns = content.primary.text_columns === "One" ? 1 : 2;
	const desktopTextWidth = content.primary.text_width
		? content.primary.text_width
		: 6;
	const tabletTextWidth = 9;

	let textMargin;
	if (
		(content.primary.text_position === "Left" ||
			content.primary.text_position === "Right") &&
		textColumns === 1
	) {
		textMargin = `0`;
	}

	if (content.primary.text_position === "Centre") {
		textMargin = `0 auto`;
	}

	return (
		<FlexContainer
			desktopPadding={pageType === `collection` ? `0 60px 0 135px` : `0 60px`}
			tabletPadding={pageType === `collection` ? `0 35px 0 135px` : `0 35px`}
			mobilePadding={`0 15px`}
			cv={true}
		>
			<TransitionContainer>
				<Row
					justifyContent={desktopContentPosition(content.primary.text_position)}
				>
					<Col
						col={12}
						md={textColumns === 1 ? tabletTextWidth : 12}
						lg={textColumns === 1 ? desktopTextWidth : 12}
						xl={textColumns === 1 ? 7 : 12}
					>
						<Textbox
							textMargin={textMargin}
							textWidth={textColumns === 1 ? `780px` : `1440px`}
							className={`${content.primary.text_columns.toLowerCase()}-column-text`}
							textColumns={textColumns}
							dangerouslySetInnerHTML={{
								__html: content.primary.text.html,
							}}
						/>
					</Col>
				</Row>
			</TransitionContainer>
		</FlexContainer>
	);
};
