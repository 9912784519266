import React, { useState, useRef } from "react";
import styled from "styled-components";
import { useClickAway } from "react-use";

import {
	FacebookShareButton,
	PinterestShareButton,
	TwitterShareButton,
	WhatsappShareButton,
	LinkedinShareButton,
} from "react-share";

// Components
import { DropdownIcon } from "../icons/dropdown-icon";

const Share = styled.div`
	position: relative;
	width: ${(props) => props.width};

	& .toggle-share-container {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;

		width: ${(props) => props.width};

		&:hover {
			color: inherit;
		}
	}

	& .share-buttons-container {
		top: 30px;
		right: ${(props) => props.right};
		left: ${(props) => props.left};

		position: absolute;

		width: 110px;
		background: white;

		border-radius: 0;

		background-color: hsl(0, 0%, 100%);
		box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);

		z-index: 1;
		box-sizing: border-box;

		& ol {
			padding: 0;
			margin: 0;

			& li {
				& button {
					width: 100%;
					padding: 8px 12px;

					text-align: left;

					&:hover {
						background-color: #f5f5f5;
						color: inherit;
					}
				}
			}
		}
	}
`;

export const ShareButtons = ({ title, text, location, image, pageType }) => {
	const [isShareVisible, setIsShareVisible] = useState(false);

	const ref = useRef(null);
	useClickAway(ref, () => {
		setIsShareVisible(false);
	});

	return (
		<Share
			ref={ref}
			left={pageType === `journal` ? `0` : `unset`}
			right={pageType === `journal` ? `unset` : `0`}
			width={pageType === `journal` ? `110px` : `70px`}
		>
			<div>
				<button
					className="toggle-share-container"
					onClick={() => setIsShareVisible(!isShareVisible)}
				>
					<span>Share</span>
					<DropdownIcon isOpen={isShareVisible} />
				</button>
			</div>
			{isShareVisible && (
				<div className="share-buttons-container">
					<ol>
						<li>
							<FacebookShareButton
								resetButtonStyle={false}
								url={location}
								quote={title}
							>
								Facebook
							</FacebookShareButton>
						</li>
						<li>
							<PinterestShareButton
								resetButtonStyle={false}
								url={location}
								media={image}
								description={title}
							>
								Pinterest
							</PinterestShareButton>
						</li>
						<li>
							<TwitterShareButton
								resetButtonStyle={false}
								url={location}
								title={title}
							>
								Twitter
							</TwitterShareButton>
						</li>
						<li>
							<WhatsappShareButton
								resetButtonStyle={false}
								url={location}
								title={title}
							>
								WhatsApp
							</WhatsappShareButton>
						</li>
						<li>
							<LinkedinShareButton
								resetButtonStyle={false}
								url={location}
								title={title}
								summary={text}
							>
								LinkedIn
							</LinkedinShareButton>
						</li>
					</ol>
				</div>
			)}
		</Share>
	);
};
