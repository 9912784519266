import React, { useState, useEffect } from "react";
import styled from "styled-components";
import addToMailchimp from "gatsby-plugin-mailchimp";

// Transitions
import { TransitionContainer } from "../containers/transition-container";

// Styles
import { secondaryDark, tertiary, secondaryLight } from "../utils/colors";

const FlexRow = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;

	@media (max-width: 900px) {
		justify-content: flex-start;
	}

	@media (max-width: 768px) {
		width: auto;
		margin: 0 -15px;
	}
`;

const Col = styled.div`
	width: 100%;
	max-width: ${(props) => props.maxWidth}px;

	padding: 0 15px;

	&.display-on-mobile {
		display: none;
	}

	@media (max-width: 900px) {
		max-width: 50%;
	}

	@media (max-width: 600px) {
		max-width: 100%;

		&.display-on-mobile {
			display: block;
		}
	}
`;

const MailchimpFormContainer = styled.div`
	position: relative;
	width: 100%;
	background-color: ${tertiary};

	min-height: 266px;

	padding: 75px;

	@media (max-width: 1200px) {
		padding: 60px;
	}

	@media (max-width: 1024px) {
		padding: 35px;
	}

	@media (max-width: 768px) {
		padding: 30px 15px;
	}

	@media (max-width: 600px) {
		& .mailchimp-signup-form {
			margin: 0;
		}

		& .text {
			margin: 0 0 30px 0;
		}
	}

	& .title {
		margin: 0 0 25px 0;
	}

	& .text {
		& p {
			margin: 0;
		}
	}

	& input {
		::-webkit-input-placeholder {
			font-family: "MatterSQ-Regular", "Helvetica Neue", "Lucida Grande",
				sans-serif;

			font-size: 16px;
			line-height: 125%;

			color: ${secondaryDark};
		}
		::-moz-placeholder {
			font-family: "MatterSQ-Regular", "Helvetica Neue", "Lucida Grande",
				sans-serif;

			font-size: 16px;
			line-height: 125%;

			color: ${secondaryDark};
		}
		:-ms-input-placeholder {
			font-family: "MatterSQ-Regular", "Helvetica Neue", "Lucida Grande",
				sans-serif;

			font-size: 16px;
			line-height: 125%;

			color: ${secondaryDark};
		}
		:-moz-placeholder {
			font-family: "MatterSQ-Regular", "Helvetica Neue", "Lucida Grande",
				sans-serif;

			font-size: 16px;
			line-height: 125%;

			color: ${secondaryDark};
		}
		::placeholder {
			font-family: "MatterSQ-Regular", "Helvetica Neue", "Lucida Grande",
				sans-serif;

			font-size: 16px;
			line-height: 125%;

			color: ${secondaryDark};
		}
	}

	& .gdpr-container {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		align-items: flex-start;

		&.display-on-mobile {
			display: none;
		}

		margin: 25px 0 0 0;

		& p {
			margin: 0;
		}

		& .gdpr-checkbox {
			width: 16px;
			height: 16px;

			position: relative;

			margin: 0 10px 0 0;
		}
		& .gdpr-checkbox label {
			width: 14px;
			height: 14px;
			cursor: pointer;
			position: absolute;
			top: 0;
			left: 0;
			border: 1px solid ${secondaryDark};
		}
		& .gdpr-checkbox label:after {
			content: "";
			width: 9px;
			height: 5px;
			position: absolute;
			top: 2px;
			left: 2px;
			border: 1px solid ${secondaryDark};
			border-top: none;
			border-right: none;
			background: transparent;
			opacity: 0;
			-webkit-transform: rotate(-45deg);
			transform: rotate(-45deg);
		}
		& .gdpr-checkbox label:hover::after {
			opacity: 1;
		}
		& .gdpr-checkbox input[type="checkbox"] {
			width: 16px;
			height: 16px;

			visibility: hidden;
		}
		& .gdpr-checkbox input[type="checkbox"]:checked + label:after {
			opacity: 1;
		}

		@media (max-width: 600px) {
			margin: 0 0 30px 0;

			&.display-on-desktop {
				display: none;
			}

			&.display-on-mobile {
				display: flex;
			}
		}
	}
`;

const InputContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;

	box-sizing: content-box;

	& .field {
		border-bottom: 1px solid ${secondaryDark};

		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: baseline;

		width: 100%;
		margin: 0 0 30px 0;
	}

	& input {
		width: 100%;

		font-size: 16px;
		line-height: 125%;

		padding: 0 0 4px 0;
		margin: 0;
		background-color: transparent;
		-webkit-appearance: none;

		border: 0;

		color: ${(props) => props.textColor};
		position: relative;

		&:focus {
			outline: none;
		}
	}
`;

const ThankYouMessage = styled.div`
	font-size: 16px;
	line-height: 125%;

	& p {
		margin: 0 0 10px 0;
	}
`;

const SignUpButton = styled.button`
	color: ${secondaryDark};

	font-size: 16px;
	line-height: 125%;

	-webkit-appearance: none;

	border: 0;
	margin: 0;

	position: relative;

	cursor: pointer;

	&:focus {
		outline: none;
	}

	border: 1px solid ${secondaryDark};
	padding: 7px;

	transition: 150ms all ease;

	text-transform: capitalize;

	&:hover {
		color: ${tertiary};
		background-color: ${secondaryDark};
	}
`;

const PrivacyMessage = styled.div`
	width: 100%;
	max-width: 320px;

	&.display-on-mobile {
		display: none;
	}

	& p {
		margin: 10px 0 0 0;
	}

	& a {
		border-bottom: 1px solid ${secondaryLight};

		&:hover {
			color: ${(props) => props.hoverColor};
			border-bottom: 1px solid ${(props) => props.hoverColor};
		}
	}

	@media (max-width: 1024px) {
		width: 100%;
	}

	@media (max-width: 600px) {
		max-width: 100%;

		&.display-on-desktop {
			display: none;
		}

		&.display-on-mobile {
			display: block;
		}
	}
`;

const GDPRContainer = styled.div`
	visibility: ${(props) => props.visibility};
`;

export const MailchimpSignUpForm = ({
	content,
	tag,
	desktopWidth,
	desktopMargin,
	mobileMargin,
	primaryColor,
	mobileMessage,
}) => {
	const [mailchimpMessage, setMailchimpMessage] = useState(null);
	const [mailchimpErrorMessage, setMailchimpErrorMessage] = useState(false);

	const mailchimpContent = content.primary.mailchimp_form.document.data;

	const [email, setEmail] = useState("");
	const [name, setName] = useState("");
	const [formMessage, setFormMessage] = useState(null);

	const [isGDPRChecked, setIsGDPRChecked] = useState(true);

	// 1. via `.then`
	const handleSubmit = (e) => {
		e.preventDefault();

		addToMailchimp(email, { NAME: name }) // listFields are optional if you are only capturing the email address.
			.then((data) => {
				// I recommend setting data to React state
				// but you can do whatever you want (including ignoring this `then()` altogether)
				if (data.result === "error") {
					if (data.msg === "The email you entered is not valid.") {
						setFormMessage("Invalid Email");
						setName("");
					}

					if (data.msg.includes("already subscribed")) {
						setFormMessage(`Email is already subscribed`);
					}
				} else {
					setMailchimpMessage(
						"Thank you for signing up to the Christian Watson mailing list."
					);
					setEmail("");
					setName("");
					e.target.reset();
				}
			})
			.catch(() => {
				// unnecessary because Mailchimp only ever
				// returns a 200 status code
				// see below for how to handle errors
			});
	};

	useEffect(() => {
		if (mailchimpMessage !== null) {
			const resetFormVisbility = setTimeout(() => {
				setMailchimpMessage(null);
				setFormMessage(null);
			}, 5000);
			return () => clearTimeout(resetFormVisbility);
		}
	}, [mailchimpMessage]);

	return (
		<TransitionContainer>
			<MailchimpFormContainer>
				<FlexRow>
					<Col maxWidth={800}>
						<h2 className="title">Newsletter</h2>
					</Col>
				</FlexRow>

				<form
					onSubmit={(e) => handleSubmit(e)}
					className="mailchimp-signup-form"
				>
					<FlexRow>
						<Col maxWidth={400}>
							<div
								className="text"
								dangerouslySetInnerHTML={{
									__html: mailchimpContent.text.html,
								}}
							/>

							<GDPRContainer
								visibility={mailchimpMessage === null ? `visible` : `hidden`}
								className="gdpr-container display-on-desktop"
							>
								<div className="gdpr-checkbox">
									<input
										type="checkbox"
										id="gdpr-checkbox"
										name="gdpr-checkbox"
										checked={isGDPRChecked}
										onChange={() => setIsGDPRChecked(!isGDPRChecked)}
										required
									/>
									<label htmlFor="gdpr-checkbox"></label>
								</div>

								<PrivacyMessage
									className="small-text display-on-desktop"
									hoverColor={primaryColor}
									dangerouslySetInnerHTML={{
										__html: mailchimpContent.privacy_policy.html,
									}}
								/>
							</GDPRContainer>
						</Col>

						<Col maxWidth={400}>
							{mailchimpMessage === null ? (
								<InputContainer textColor={primaryColor} color={secondaryDark}>
									<div className="field">
										<input
											type="name"
											onChange={(e) => setName(e.target.value)}
											value={name}
											placeholder="Name"
											name="name"
											required
											autoComplete="name"
										/>
									</div>
									<div className="field">
										<input
											type="email"
											onChange={(e) => setEmail(e.target.value)}
											value={email}
											placeholder={`Email`}
											name="email"
											required
											autoComplete="email"
										/>
									</div>

									<GDPRContainer
										visibility={
											mailchimpMessage === null ? `visible` : `hidden`
										}
										className="gdpr-container display-on-mobile"
									>
										<div className="gdpr-checkbox">
											<input
												type="checkbox"
												id="gdpr-checkbox"
												name="gdpr-checkbox"
												checked={isGDPRChecked}
												onChange={() => setIsGDPRChecked(!isGDPRChecked)}
												required
											/>
											<label htmlFor="gdpr-checkbox"></label>
										</div>

										<PrivacyMessage
											className="small-text"
											hoverColor={primaryColor}
											dangerouslySetInnerHTML={{
												__html: mailchimpContent.privacy_policy.html,
											}}
										/>
									</GDPRContainer>

									<SignUpButton type="submit">
										{mailchimpContent.call_to_action_text}
									</SignUpButton>

									{formMessage !== null && <p>{formMessage}</p>}
								</InputContainer>
							) : (
								<ThankYouMessage>{mailchimpMessage}</ThankYouMessage>
							)}
						</Col>
					</FlexRow>
				</form>
			</MailchimpFormContainer>
		</TransitionContainer>
	);
};
