import React, { useEffect, useState } from "react";
import styled from "styled-components";

// Styles
import { secondaryLight, primaryLight } from "../utils/colors";

const FilterContainer = styled.div`
	position: fixed;

	top: 200px;
	left: 0;

	width: 135px;

	z-index: 1;

	& ol {
		margin: 0;
		padding: 0 15px;

		& li {
			& a {
				&:hover {
					color: ${secondaryLight};
				}
			}

			& a.current {
				color: ${secondaryLight};
				&:hover {
					color: inherit;
				}
			}
		}
	}

	& ol.mobile-filter-button {
		display: none;
	}

	@media (max-width: 768px) {
		top: 52px;
		left: 0;
		right: 0;

		width: 100%;

		background-color: ${primaryLight};

		& ol.mobile-filter-button {
			display: block;
		}

		& ol.filter-list {
			display: ${(props) => props.isOpen};
		}

		& ol {
			& li {
				line-height: 50px;
			}
		}
	}
`;

const FilterButton = styled.button`
	transition: 150ms color ease;

	color: ${(props) => (props.isActiveFilter ? secondaryLight : `inherit`)};

	&:hover {
		color: ${secondaryLight};
	}
`;

export const SingleCollectionFilters = ({
	activeFilter,
	setActiveFilter,
	products,
}) => {
	const [isOpen, setIsOpen] = useState(false);

	const filters = Array.from(
		new Set(
			products
				.map(
					(product, index) =>
						product.product.document.data.linked_product.product_type
				)
				.flat()
		)
	)
		.sort()
		.map((filter) => (
			<li key={`filter_${filter}`}>
				<FilterButton
					onClick={() => {
						setActiveFilter(filter);
						document.querySelector("#collection-products").scrollIntoView({
							behavior: "smooth",
							block: "start",
						});
					}}
					isActiveFilter={activeFilter === filter}
				>
					{filter}
				</FilterButton>
			</li>
		));

	useEffect(() => {
		let previousFilter;

		if (activeFilter !== previousFilter) {
			setIsOpen(false);
		}

		previousFilter = activeFilter;
	}, [activeFilter]);

	return (
		<FilterContainer isOpen={isOpen === true ? `block` : `none`}>
			<ol className="mobile-filter-button">
				<li>
					<button onClick={() => setIsOpen(!isOpen)}>
						{isOpen === true ? `Close` : `Filter`}
					</button>
				</li>
			</ol>
			<ol className="filter-list">
				<li>
					<FilterButton
						onClick={() => {
							setActiveFilter("All");
							document.querySelector("#collection-products").scrollIntoView({
								behavior: "smooth",
								block: "start",
							});
						}}
						isActiveFilter={activeFilter === "All"}
					>
						All
					</FilterButton>
				</li>

				{filters}
			</ol>
		</FilterContainer>
	);
};
