import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Row, Col } from "styled-bootstrap-grid";
import Select, { components } from "react-select";
import { useForm, Controller } from "react-hook-form";

// Styles
import { secondaryDark, tertiary } from "../utils/colors";

// Components
import { FlexContainer } from "../containers/flex-container";
import { DropdownIcon } from "../icons/dropdown-icon";

// Utils
import { convertToKebabCase } from "../utils/convert-to-kebab";
import { encodeFormWithFormData } from "../utils/encode-form";
import { capitalize } from "../utils/capitalize";

const EnquiryFormContainer = styled.div`
	width: 100%;

	padding: 60px 0;

	& span {
		font-size: 12px;
	}

	@media (max-width: 1024px) {
		padding: 60px 35px;
	}

	@media (max-width: 768px) {
		padding: 30px 0;
	}

	& h2 {
		margin: 0 0 25px 0;
	}

	& form {
		opacity: ${(props) => props.formOpacity};

		& .field {
			& input {
				width: 100%;
				margin: 0 0 20px 0;
				padding: 7px 0;

				border: 0;
				border-bottom: 1px solid ${secondaryDark};
			}

			& textarea {
				width: calc(100% - 16px);
				max-width: calc(100% - 16px);
				min-width: calc(100% - 16px);

				margin: 5px 0 25px 0;
				padding: 7px;

				-webkit-appearance: none;

				border: 1px solid ${secondaryDark};
				border-radius: 0;
			}
		}

		& label.message-title {
			margin: 6px 0;
			position: relative;
			display: block;
		}

		& .disclaimer {
			margin: 0 0 25px 0;

			max-width: calc(100% - 35px);
		}

		& button {
			border: 1px solid ${secondaryDark};
			padding: 7px;

			transition: 150ms all ease;

			&:hover {
				color: ${tertiary};
				background-color: ${secondaryDark};
			}
		}

		& input.custom-file-input {
			color: transparent;
			border-bottom: 1px solid transparent;
			width: 110px;
		}
		& input.custom-file-input::-webkit-file-upload-button {
			visibility: hidden;
		}
		& input.custom-file-input::before {
			content: "Choose File";
			color: ${secondaryDark};
			display: inline-block;
			background: transparent;
			border: 1px solid ${secondaryDark};
			border-radius: 0;
			padding: 5px 10px;
			outline: none;
			white-space: nowrap;
			-webkit-user-select: none;
			cursor: pointer;
			text-shadow: none;
		}
		& input.custom-file-input:hover::before {
			border-color: ${secondaryDark};
		}
		& input.custom-file-input:active::before {
			background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
		}

		& .gdpr-container {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			align-items: flex-start;

			& .error-message {
				width: 100%;
				margin: 0 0 10px 0;
			}

			& .flex {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				align-items: flex-start;
				justify-content: space-between;

				width: 100%;
			}

			& p {
				margin: 0;
			}

			& .gdpr-checkbox {
				width: 16px;
				height: 16px;

				position: relative;

				margin: 0 10px 0 0;
			}

			& .gdpr-checkbox label {
				width: 14px;
				height: 14px;
				cursor: pointer;
				position: absolute;
				top: 0;
				left: 0;
				border: 1px solid ${secondaryDark};
			}
			& .gdpr-checkbox label:after {
				content: "";
				width: 9px;
				height: 5px;
				position: absolute;
				top: 2px;
				left: 2px;
				border: 1px solid ${secondaryDark};
				border-top: none;
				border-right: none;
				background: transparent;
				opacity: 0;
				-webkit-transform: rotate(-45deg);
				transform: rotate(-45deg);
			}
			& .gdpr-checkbox label:hover::after {
				opacity: 1;
			}
			& .gdpr-checkbox input[type="checkbox"] {
				width: 16px;
				height: 16px;

				visibility: hidden;
			}
			& .gdpr-checkbox input[type="checkbox"]:checked + label:after {
				opacity: 1;
			}
		}

		@media (max-width: 768px) {
			margin: 25px 0 0 0;
		}
	}
`;

const Textbox = styled.div`
	max-width: 500px;
	columns: ${(props) => props.textColumns};
	column-gap: 30px;

	& p:first-of-type {
		margin-top: 0;
	}

	& p:last-of-type {
		margin-bottom: 0;
	}
`;

const OptionsContainer = styled.div`
	width: 100%;
	margin: 0 0 20px 0;

	& .enquiry-options__control {
		border: 0;
		// 1px solid ${secondaryDark};
		border-radius: 0;
		background-color: transparent;
		box-shadow: none;
		padding: 7px 0;

		&:hover {
			border-color: inherit;
		}
	}

	& .enquiry-options__single-value {
		color: inherit;
	}

	& .enquiry-options__single-value {
		margin-left: 0;
	}

	& .enquiry-options__value-container {
		padding: 0;
	}

	& .css-tlfecz-indicatorContainer {
		color: inherit;
		padding: 0;
	}

	& .enquiry-options__indicator {
		padding: 0;
	}

	& .enquiry-options__placeholder {
		color: inherit;
		padding-left: 0;

		margin-left: 0;
		margin-right: 0;
	}

	& .enquiry-options__indicator-separator {
		display: none;
	}


	& .enquiry-options__menu {
		border-radius: 0;

		& .enquiry-options__menu-list {
			padding: 0;
		}

		& .enquiry-options__option--is-focused {
			background-color: #f5f5f5;
		}

		& .enquiry-options__option--is-selected {
			background-color: ${tertiary};
			color: inherit;
		}
	}

	& .css-b8ldur-Input {
		margin: 0;
	}
`;

const ThankYouMessage = styled.div`
	font-size: 16px;
	line-height: 125%;
`;

export const EnquiryForm = ({ content, pageType }) => {
	const [checked, setChecked] = useState(true);
	const [formMessage, setFormMessage] = useState(null);

	// Form Setup
	const {
		control,
		register,
		handleSubmit,
		setValue,
		watch,
		errors,
		reset,
	} = useForm();

	useEffect(() => {
		if (formMessage !== null) {
			const fadeOutForm = setTimeout(() => {
				setFormMessage(null);
			}, 5000);
			return () => clearTimeout(fadeOutForm);
		}
	}, [formMessage]);

	const DropdownIndicator = (props) => {
		return (
			components.DropdownIndicator && (
				<components.DropdownIndicator {...props}>
					<DropdownIcon isOpen={props.selectProps.menuIsOpen} />
				</components.DropdownIndicator>
			)
		);
	};

	const dropdownOptions = {
		values: content.primary.enquiry_form.document.data.dropdown_options.map(
			(value) => {
				return (value = {
					value: convertToKebabCase(value.dropdown_option),
					label: value.dropdown_option,
					email: value.linked_email_address,
				});
			}
		),
	};

	const additionalFormFields = content.primary.enquiry_form.document.data.additional_form_fields.map(
		(field, index) => (
			<div
				className="field"
				key={`additional_form_field_${content.primary.enquiry_form.document.id}_${index}`}
			>
				<input
					name={convertToKebabCase(field.additional_form_field)}
					type="text"
					placeholder={field.additional_form_field}
					ref={register}
				/>
			</div>
		)
	);

	const [enquiryFormTitle, setEnquiryFormTitle] = useState(
		dropdownOptions.values[0].email.toLowerCase()
	);
	const watchDropdownOptions = watch("type", enquiryFormTitle);

	useEffect(() => {
		if (
			watchDropdownOptions !== "" &&
			watchDropdownOptions.email !== undefined
		) {
			setEnquiryFormTitle(watchDropdownOptions.email.toLowerCase());
		}
	}, [watchDropdownOptions]);

	const onSubmit = (data) => {
		let formattedData;

		if (data.file !== undefined) {
			formattedData = {
				...data,
				file: data.file[0],
			};
		} else {
			formattedData = data;
		}

		fetch("/", {
			method: "POST",
			body: encodeFormWithFormData({
				"form-name": `${enquiryFormTitle.toLowerCase()}-enquiry`,
				subject: `${capitalize(enquiryFormTitle)} Enquiry`,
				...formattedData,
			}),
		})
			.then(() => {
				setFormMessage(
					"Thank you for submitting your enquiry. We'll be in touch soon."
				);
				reset();
			})
			.catch((error) => {
				console.log(error);
				setFormMessage(
					"An error occurred while submitting your enquiry. Please email us directly at: info@christian-watson.com"
				);
			});
	};

	const hiddenNetlifyForms = content.primary.enquiry_form.document.data.dropdown_options.map(
		(value, index) => (
			<form
				name={`${value.linked_email_address.toLowerCase()}-enquiry`}
				data-netlify="true"
				netlify-honeypot="bot-field"
				hidden
				key={`hidden_form_${content.primary.enquiry_form.document.id}_${index}`}
			>
				<input
					type="hidden"
					name="form-name"
					value={`${value.linked_email_address.toLowerCase()}-enquiry`}
				/>
				<input
					type="hidden"
					name="subject"
					value={`${capitalize(value.linked_email_address)} Enquiry`}
				/>
				<input type="text" name="name" />
				<input type="email" name="email" />
				<input type="tel" name="telephone" />

				{content.primary.enquiry_form.document.data.additional_form_fields.map(
					(field, index) => (
						<div
							className="field"
							key={`additional_hidden_form_fields_${content.primary.enquiry_form.document.id}_${index}`}
						>
							<input
								type="text"
								name={convertToKebabCase(field.additional_form_field)}
							/>
						</div>
					)
				)}
				<textarea name="message" />

				{content.primary.enquiry_form.document.data.enable_file_upload && (
					<input
						type="file"
						name="file"
						accept=".jpg, .jpeg, .pdf, .doc, .docx, .xml, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
						required
					/>
				)}
			</form>
		)
	);

	return (
		<FlexContainer
			desktopPadding={pageType === `collection` ? `0 60px 0 135px` : `0 60px`}
			tabletPadding={pageType === `collection` ? `0 35px 0 135px` : `0 35px`}
			mobilePadding={`0 15px`}
			bgColor={tertiary}
			cv={true}
		>
			<EnquiryFormContainer formOpacity={formMessage === null ? 1 : 0}>
				<Row justifyContent="center">
					<Col col={12} md={6} lg={5}>
						<Textbox
							dangerouslySetInnerHTML={{
								__html:
									content.primary.enquiry_form.document.data.form_title.html,
							}}
						/>
						<Textbox
							dangerouslySetInnerHTML={{
								__html:
									content.primary.enquiry_form.document.data.form_text.html,
							}}
						/>
					</Col>
					<Col col={12} md={6} lg={5}>
						{formMessage !== null && (
							<ThankYouMessage>
								<p>{formMessage}</p>
							</ThankYouMessage>
						)}

						<form
							name={`${enquiryFormTitle.toLowerCase()}-enquiry`}
							method="POST"
							onSubmit={handleSubmit(onSubmit)}
							data-netlify="true"
							data-netlify-honeypot="bot-field"
						>
							<div className="field">
								<input type="hidden" name="bot-field" />
								<input
									type="hidden"
									name="form-name"
									name={`${enquiryFormTitle.toLowerCase()}-enquiry`}
								/>
								<input
									type="hidden"
									name="subject"
									value={`${capitalize(enquiryFormTitle)} Enquiry`}
								/>
								{/* errors will return when field validation fails  */}
								{errors.name && <span>This field is required</span>}
								<input
									name="name"
									type="text"
									placeholder="Name"
									autoComplete="name"
									ref={register({ required: true, maxLength: 80 })}
								/>
							</div>
							<div className="field">
								{/* errors will return when field validation fails  */}
								{errors.email && <span>This field is required</span>}
								<input
									name="email"
									type="email"
									placeholder="Email"
									autoComplete="email"
									ref={register({
										required: true,
									})}
								/>
							</div>
							<div className="field">
								<input
									name="telephone"
									type="tel"
									placeholder="Telephone"
									autoComplete="telephone"
									ref={register}
								/>
							</div>
							{additionalFormFields}
							<OptionsContainer>
								{/* errors will return when field validation fails  */}
								{errors.type && <span>This field is required</span>}
								<Controller
									name="type"
									as={Select}
									options={dropdownOptions.values}
									placeholder={dropdownOptions.values[0].label}
									classNamePrefix="enquiry-options"
									components={{ DropdownIndicator }}
									isSearchable={false}
									control={control}
									rules={{ required: true }}
									defaultValue={dropdownOptions.values[0].value}
								/>
							</OptionsContainer>
							<div className="field">
								<label className="message-title">Message</label>
								{/* errors will return when field validation fails  */}
								{errors.message && <span>This field is required</span>}
								<textarea
									name="message"
									rows="10"
									ref={register({ required: true })}
									placeholder={
										content.primary.enquiry_form.document.data
											.form_message_placeholder
									}
								/>
							</div>
							{content.primary.enquiry_form.document.data
								.enable_file_upload && (
								<>
									<div className="field">
										<label className="message-title">Upload Form</label>
										{/*https://github.com/react-hook-form/react-hook-form/blob/master/examples/customValidation.tsx*/}
										{errors.file && errors.file.type === "fileSize" && (
											<div>
												<span>
													File is too large. Please email us directly at:
													info@christian-watson.com
												</span>
											</div>
										)}

										{errors.file && errors.file.type !== "fileSize" && (
											<div>
												<span>This field is required</span>
											</div>
										)}
										<input
											type="file"
											name="file"
											accept=".jpg, .jpeg, .pdf, .doc, .docx, .xml, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
											className="custom-file-input"
											ref={register({
												required: true,
												validate: {
													fileSize: (value) =>
														value[0] !== undefined && value[0].size < 300000,
												},
											})}
										/>
									</div>

									<Textbox
										className="disclaimer small-text"
										dangerouslySetInnerHTML={{
											__html:
												content.primary.enquiry_form.document.data
													.file_upload_text.html,
										}}
									/>
								</>
							)}

							<div className="gdpr-container">
								{/* errors will return when field validation fails  */}
								{errors.gdprcheck && (
									<div className="error-message">
										<span>This field is required</span>
									</div>
								)}

								<div className="flex">
									<div
										className="gdpr-checkbox"
										onClick={() => setChecked(!checked)}
									>
										<input
											type="checkbox"
											id="gdpr-checkbox"
											name="gdprcheck"
											value={checked}
											checked={checked}
											onChange={(e) => {
												setChecked(e.target.checked);
											}}
											ref={register({
												required: true,
											})}
										/>
										<label htmlFor="gdprcheck"></label>
									</div>
									<Textbox
										className="disclaimer small-text"
										dangerouslySetInnerHTML={{
											__html:
												content.primary.enquiry_form.document.data
													.form_disclaimer_text.html,
										}}
									/>
								</div>
							</div>

							<button type="submit">Submit</button>
						</form>

						{hiddenNetlifyForms}
					</Col>
				</Row>
			</EnquiryFormContainer>
		</FlexContainer>
	);
};
