import React, { useState } from "react";
import styled from "styled-components";
import { Row, Col } from "styled-bootstrap-grid";

// Intersection Observer
import Observer from "@researchgate/react-intersection-observer";

// Context
import { MenuColorConsumer } from "../context/menu-color";

// Components
import { FlexContainer } from "../containers/flex-container";
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";

const Collection = styled.div`
	.collection-title {
		margin: 100px 0 0 0;
	}

	.collection-text {
		margin: 25px 0 0 0;

		@media (max-width: 768px) {
			margin: 40px 0 0 0;
		}
	}

	& img {
		// width: auto;
		// height: 100%;

		// max-height: calc(100vh - 340px);
		// margin: 0 0 0 auto;
	}
`;

const CollectionsIntroductionContent = ({
	content,
	pageType,
	setMenuColor,
}) => {
	const options = {
		onChange: (entry, unobserve) => onIntersectionChange(entry, unobserve),
		rootMargin: "-53px 0px 0px 0px",
	};

	const onIntersectionChange = (entry, unobserve) => {
		if (entry.isIntersecting) {
			setMenuColor(`transparent`);
		} else {
			setMenuColor(`#fff`);
		}
	};

	return (
		<>
			<Observer {...options}>
				<div>
					{content.primary.collection_image.fluid !== null && (
						<AspectRatioImageContainer image={content.primary.collection_image}>
							<img
								srcSet={content.primary.collection_image.fluid.srcSetWebp}
								src={content.primary.collection_image.fluid.srcWebp}
								alt={content.primary.collection_image.alt}
								loading={`lazy`}
							/>
						</AspectRatioImageContainer>
					)}
				</div>
			</Observer>

			<FlexContainer
				desktopPadding={pageType === `collection` ? `0 60px 0 135px` : `0 60px`}
				tabletPadding={pageType === `collection` ? `0 35px 0 135px` : `0 35px`}
				mobilePadding={`0 15px`}
				cv={true}
			>
				<Collection>
					<Row justifyContent={`end`}>
						<Col col={12} md={10} lg={8}>
							<div
								className="collection-title"
								dangerouslySetInnerHTML={{
									__html: content.primary.collection_title.html,
								}}
							/>
						</Col>
					</Row>

					<Row justifyContent={`end`}>
						<Col col={12} md={10} lg={8}>
							<div
								className="collection-text"
								dangerouslySetInnerHTML={{
									__html: content.primary.collection_text.html,
								}}
							/>
						</Col>
					</Row>
				</Collection>
			</FlexContainer>
		</>
	);
};

export const LimitedEditionCollectionsIntroduction = ({
	content,
	pageType,
}) => (
	<MenuColorConsumer>
		{({ setMenuColor }) => (
			<CollectionsIntroductionContent
				content={content}
				pageType={pageType}
				setMenuColor={setMenuColor}
			/>
		)}
	</MenuColorConsumer>
);
