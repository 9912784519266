import React from "react";
import styled from "styled-components";
import { Row, Col } from "styled-bootstrap-grid";

// Transitions
import { TransitionContainer } from "../containers/transition-container";

// Utils
import { desktopContentPosition } from "../utils/content-size-position";

// Components
import { FlexContainer } from "../containers/flex-container";

const Textbox = styled.div`
	& p:last-of-type {
		margin-bottom: 0;
	}

	& p:empty {
		display: inline-block;
		margin: 0;
	}

	& p {
		margin: 0 20px 0 0;
	}
`;

const LinkContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
`;

export const SocialLinks = ({ content, pageType }) => {
	const links = content.items.map((link, index) => (
		<Textbox
			key={`single_social_link_${index}`}
			dangerouslySetInnerHTML={{
				__html: link.social_link.html,
			}}
		/>
	));

	return (
		<FlexContainer
			desktopPadding={pageType === `collection` ? `0 60px 0 135px` : `0 60px`}
			tabletPadding={pageType === `collection` ? `0 35px 0 135px` : `0 35px`}
			mobilePadding={`0 15px`}
			cv={true}
		>
			<TransitionContainer>
				<Row>
					<Col col={12}>
						<LinkContainer>{links}</LinkContainer>
					</Col>
				</Row>
			</TransitionContainer>
		</FlexContainer>
	);
};
