import React from "react";
import styled from "styled-components";
import { Row, Col } from "styled-bootstrap-grid";
import { Link } from "gatsby";

// Components
import { FlexContainer } from "../containers/flex-container";
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";

// Styles
import { tertiary } from "../utils/colors";

const RelatedProductsContainer = styled.div`
	width: 100%;

	& .title {
		margin: 0 0 25px 0;
	}

	& .product {
		& p {
			margin: 10px 0 40px 0;
		}
	}

	& img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
`;

export const RelatedProducts = ({ content, pageType }) => {
	const products = content.items
		.filter((item) => item.product !== null)
		.map((item, index) => (
			<Col
				col={6}
				md={6}
				key={`single_related_product_${item.product.document.id}_${index}`}
			>
				<Link
					to={`/products/${item.product.document.data.linked_product.handle}`}
				>
					<div className="product">
						<AspectRatioImageContainer image={null} padding={75}>
							{item.product.document.data.thumbnail.fluid !== null && (
								<img
									srcSet={item.product.document.data.thumbnail.fluid.srcSetWebp}
									src={item.product.document.data.thumbnail.fluid.srcWebp}
									alt={item.product.document.data.thumbnail.alt}
									loading={`lazy`}
								/>
							)}
						</AspectRatioImageContainer>
						<p>{item.product.document.data.linked_product.title}</p>
					</div>
				</Link>
			</Col>
		));

	if (products.length >= 1) {
		return (
			<FlexContainer
				largeDesktopPadding={
					pageType === `collection` ? `0 60px 0 135px` : `75px 135px 35px 135px`
				}
				desktopPadding={
					pageType === `collection` ? `0 60px 0 135px` : `75px 135px 35px 135px`
				}
				tabletPadding={
					pageType === `collection` ? `0 35px 0 135px` : `35px 35px 0 35px`
				}
				mobilePadding={`35px 15px 0 15px`}
				bgColor={`#f8f8f8`}
				cv={true}
			>
				<RelatedProductsContainer>
					<Row>
						<Col col={12}>
							<h2 className="title">Related Products</h2>
						</Col>
					</Row>
					<Row>{products}</Row>
				</RelatedProductsContainer>
			</FlexContainer>
		);
	} else {
		return null;
	}
};
