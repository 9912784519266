import React from "react";

//  Modules
import { Image } from "../images/image";
import { Gallery } from "../images/gallery";
import { GalleryWithText } from "../images/gallery-with-text";
import { ImageBanner } from "../images/image-banner";
import { VideoBanner } from "../videos/video-banner";
import { Text } from "../text/text";
import { ArticleText } from "../text/article-text";
import { OpeningText } from "../text/opening-text";
import { MailchimpSignUpForm } from "../mailchimp/sign-up-form";
import { Collections } from "../collections/collections";
import { CollectionsIntroduction } from "../collections/collections-introduction";
import { LimitedEditionCollectionsIntroduction } from "../collections/limited-edition-collections-introduction";
import { VideoWithControls } from "../videos/video-with-controls";
import { CollectionProducts } from "../collections/collection-products";
import { RelatedProducts } from "../product/related-products";
import { RelatedPages } from "../pages/related-pages";
import { EnquiryForm } from "../forms/enquiry-form";
import { ImageWithText } from "../images/image-with-text";
import { Faqs } from "../text/faqs";
import { SocialLinks } from "../text/social-links";

// Utils
import { LimitedEditionGallery } from "../images/limited-edition-gallery";

export const DetermineModuleToDisplay = ({
	content,
	article,
	pageType,
	activeFilter,
	setActiveFilter,
	contentIndex,
}) => {
	switch (content.slice_type) {
		case "image":
			return (
				<Image
					content={content}
					pageType={pageType}
					contentIndex={contentIndex}
				/>
			);

		case "slideshow":
			return (
				<Gallery
					content={content}
					pageType={pageType}
					contentIndex={contentIndex}
				/>
			);

		case "slideshow_with_text":
			return (
				<GalleryWithText
					content={content}
					pageType={pageType}
					contentIndex={contentIndex}
				/>
			);

		case "video":
			return (
				<VideoWithControls
					content={content}
					pageType={pageType}
					contentIndex={contentIndex}
				/>
			);

		case "mailchimp":
			return (
				<MailchimpSignUpForm
					content={content}
					pageType={pageType}
					contentIndex={contentIndex}
				/>
			);

		case "text":
			return (
				<>
					{pageType === "article" ? (
						<ArticleText
							content={content}
							pageType={pageType}
							contentIndex={contentIndex}
						/>
					) : (
						<Text
							content={content}
							pageType={pageType}
							contentIndex={contentIndex}
						/>
					)}
				</>
			);

		case "social_links":
			return (
				<SocialLinks
					content={content}
					pageType={pageType}
					contentIndex={contentIndex}
				/>
			);

		case "opening_text":
			return (
				<OpeningText
					content={content}
					pageType={pageType}
					contentIndex={contentIndex}
					article={article}
				/>
			);

		case "products":
			return (
				<CollectionProducts
					content={content}
					pageType={pageType}
					contentIndex={contentIndex}
					activeFilter={activeFilter}
					setActiveFilter={setActiveFilter}
				/>
			);

		case "collections":
			return (
				<Collections
					content={content}
					pageType={pageType}
					contentIndex={contentIndex}
				/>
			);

		case "image_-_banner":
			return (
				<ImageBanner
					content={content}
					pageType={pageType}
					contentIndex={contentIndex}
				/>
			);

		case "video_-_banner":
			return (
				<VideoBanner
					content={content}
					pageType={pageType}
					contentIndex={contentIndex}
				/>
			);

		case "le_introduction":
			return (
				<LimitedEditionCollectionsIntroduction
					content={content}
					pageType={pageType}
					contentIndex={contentIndex}
				/>
			);

		case "introduction":
			return (
				<CollectionsIntroduction
					content={content}
					pageType={pageType}
					contentIndex={contentIndex}
				/>
			);

		case "related_products":
			return (
				<RelatedProducts
					content={content}
					pageType={pageType}
					contentIndex={contentIndex}
				/>
			);

		case "related_pages":
			return (
				<RelatedPages
					content={content}
					pageType={pageType}
					contentIndex={contentIndex}
				/>
			);

		case "enquiry_form":
			return (
				<EnquiryForm
					content={content}
					pageType={pageType}
					contentIndex={contentIndex}
				/>
			);

		case "image_with_text":
			return (
				<ImageWithText
					content={content}
					pageType={pageType}
					contentIndex={contentIndex}
				/>
			);

		case "limited_edition":
			return (
				<LimitedEditionGallery
					content={content}
					pageType={pageType}
					contentIndex={contentIndex}
				/>
			);

		case "faq":
			return (
				<Faqs
					content={content}
					pageType={pageType}
					contentIndex={contentIndex}
				/>
			);

		default:
			return null;
	}
};
