import React from "react";
import styled from "styled-components";

// Components
import { FlexContainer } from "../containers/flex-container";
import { ShareButtons } from "../buttons/share-buttons";

const Grid = styled.div`
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-gap: 30px;

	@media (max-width: 767px) {
		display: block;

		grid-gap: 0;
	}
`;

const ArticleDetails = styled.div`
	grid-column: 1 / 4;

	& p {
		margin: 0;
	}

	& p:last-of-type {
		margin: 0 0 1em 0;
	}

	@media (max-width: 767px) {
		grid-column: unset;

		margin: 0 0 45px 0;
	}
`;

const Textbox = styled.div`
	max-width: 570px;
	grid-column: 4 / 13;

	& h1 {
		font-size: 16px;
		line-height: 125%;

		letter-spacing: 0.1em;
		text-transform: uppercase;

		margin: 0 0 1em 0;
	}

	& p:first-of-type {
		margin-top: 0;
	}

	& p:last-of-type {
		margin-bottom: 0;
	}

	@media (max-width: 767px) {
		grid-column: unset;
	}
`;

const DetailsTextBox = styled(Textbox)`
	grid-column: none;
`;

export const OpeningText = ({ content, article }) => (
	<FlexContainer
		desktopPadding={`0 60px`}
		tabletPadding={`0 35px`}
		mobilePadding={`0 15px`}
		cv={true}
	>
		<Grid>
			<ArticleDetails>
				<DetailsTextBox
					dangerouslySetInnerHTML={{
						__html: article.data.title.html,
					}}
				/>
				<p>By {article.data.author.text}</p>
				{/* <p>{article.first_publication_date}</p> */}

				<ShareButtons
					pageType={`journal`}
					title={article.data.title.text}
					text={content.primary.text.text}
					location={`https://christian-watson.com${article.url}`}
					image={
						article.data.thumbnail.fluid !== null
							? article.data.thumbnail.fluid.src
							: ``
					}
				/>
			</ArticleDetails>

			<Textbox
				dangerouslySetInnerHTML={{
					__html: content.primary.text.html,
				}}
			/>
		</Grid>
	</FlexContainer>
);
