import React from "react";
import styled from "styled-components";
import { Row, Col } from "styled-bootstrap-grid";

// Components
import { FlexContainer } from "../containers/flex-container";
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";

const Collection = styled.div`
	.collection-title,
	.collection-text {
		margin: 40px 0 0 0;
	}
`;

const ImageContainer = styled.div`
	position: relative;
	max-height: calc(100vh - 200px);

	& img {
		max-height: calc(100vh - 200px);
		object-fit: contain;
		object-position: right;
	}
`;

export const CollectionsIntroduction = ({ content, pageType }) => (
	<FlexContainer
		desktopPadding={pageType === `collection` ? `0 60px 0 135px` : `0 60px`}
		tabletPadding={pageType === `collection` ? `0 35px 0 135px` : `0 35px`}
		mobilePadding={`0 15px`}
		cv={true}
	>
		<Collection>
			<Row justifyContent={`end`}>
				<Col col={12} md={10} lg={10} xl={9}>
					<ImageContainer>
						{content.primary.collection_image.fluid !== null && (
							<AspectRatioImageContainer
								image={content.primary.collection_image}
								background={`transparent`}
							>
								<img
									srcSet={content.primary.collection_image.fluid.srcSetWebp}
									src={content.primary.collection_image.fluid.srcWebp}
									alt={content.primary.collection_image.alt}
									loading={`lazy`}
								/>
							</AspectRatioImageContainer>
						)}
					</ImageContainer>
				</Col>
			</Row>

			<Row justifyContent={`end`}>
				<Col col={12}>
					<div
						className="collection-title"
						dangerouslySetInnerHTML={{
							__html: content.primary.collection_title.html,
						}}
					/>
				</Col>

				<Col col={12} md={10} lg={8} xl={6}>
					<div
						className="collection-text"
						dangerouslySetInnerHTML={{
							__html: content.primary.collection_text.html,
						}}
					/>
				</Col>
			</Row>
		</Collection>
	</FlexContainer>
);
