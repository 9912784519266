import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { Row, Col } from "styled-bootstrap-grid";

// Transitions
import { TransitionContainer } from "../containers/transition-container";

// Utils
import { desktopContentPosition } from "../utils/content-size-position";

// Components
import { FlexContainer } from "../containers/flex-container";
import { ConditionalWrapper } from "../utils/conditional-wrapper";
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";

const Img = styled.img`
	object-fit: cover;
	object-position: center;

	width: 100%;
	height: 100%;
`;

const ImageCaption = styled.div``;

export const Image = ({ content, pageType, contentIndex }) => {
	if (content.primary.image.fluid !== null) {
		const isImageLandscape =
			content.primary.image.dimensions.width >
			content.primary.image.dimensions.height;

		let mdImageSize;
		let lgImageSize;

		if (parseInt(content.primary.image_size) <= 9) {
			mdImageSize = parseInt(content.primary.image_size) + 3;
		} else {
			mdImageSize = content.primary.image_size;
		}

		if (parseInt(content.primary.image_size) <= 10) {
			lgImageSize = parseInt(content.primary.image_size) + 2;
		} else {
			lgImageSize = content.primary.image_size;
		}

		return (
			<FlexContainer
				desktopPadding={pageType === `collection` ? `0 60px 0 135px` : `0 60px`}
				tabletPadding={pageType === `collection` ? `0 35px 0 135px` : `0 35px`}
				mobilePadding={`0 15px`}
				cv={true}
			>
				<TransitionContainer>
					<Row
						justifyContent={desktopContentPosition(
							content.primary.image_position
						)}
					>
						<Col
							col={isImageLandscape === true ? 12 : 10}
							md={mdImageSize}
							lg={lgImageSize}
							xl={content.primary.image_size}
						>
							<ConditionalWrapper
								condition={content.primary.link.url}
								wrapper={(children) => (
									<Link to={content.primary.link.url}>{children}</Link>
								)}
							>
								{content.primary.image.fluid !== null && (
									<>
										<AspectRatioImageContainer image={content.primary.image}>
											<Img
												srcSet={content.primary.image.fluid.srcSetWebp}
												src={content.primary.image.fluid.srcWebp}
												loading={`lazy`}
												alt={
													content.primary.link.type !== null
														? `Link to ${content.primary.link.type}`
														: content.primary.image.alt
												}
											/>
										</AspectRatioImageContainer>
										{content.primary.image_credit !== undefined && (
											<>
												{content.primary.image_credit.text !== "" && (
													<ImageCaption
														className="small-text"
														dangerouslySetInnerHTML={{
															__html: content.primary.image_credit.html,
														}}
													/>
												)}
											</>
										)}
									</>
								)}
							</ConditionalWrapper>
						</Col>
					</Row>
				</TransitionContainer>
			</FlexContainer>
		);
	} else {
		return null;
	}
};
