import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Row, Col } from "styled-bootstrap-grid";
import { Carousel } from "react-responsive-carousel";
import "../styles/gallery.css";

// Transitions
import { TransitionContainer } from "../containers/transition-container";

// Components
import { FlexContainer } from "../containers/flex-container";

// Hooks
import { useMediaQuery } from "../hooks/useMediaQuery";

const GalleryContainer = styled.div`
	position: relative;

	padding: 0;
	margin: 0;

	& .carousel.carousel-slider,
	& .slider-wrapper,
	& .image-gallery,
	& .slider,
	& .slide,
	& video,
	& img {
		height: 100%;
		max-height: 100%;
	}
`;

const SlideContainer = styled.div`
	position: relative;

	width: 100%;
	height: 100%;

	padding: 0;

	& img {
		max-width: 100%;

		object-fit: contain;
	}
`;

const Textbox = styled.div`
	margin: ${(props) => props.margin};
	text-align: left;

	& p:first-of-type {
		margin-top: 0;
	}

	& p:last-of-type {
		margin-bottom: 0;
	}

	@media (max-width: 768px) {
		margin: ${(props) => (props.mobileMargin ? props.mobileMargin : `0`)};
	}
`;

const Caption = styled.div`
	min-height: 60px;
`;

export const GalleryWithText = ({ content }) => {
	const [currentSlide, setCurrentSlide] = useState(0);
	const [totalSlides, setTotalSlides] = useState(null);
	const [activeContent, setActiveContent] = useState(null);

	// Media Query
	let isMobile = useMediaQuery("(hover: none) and (pointer: coarse)");

	useEffect(() => {
		setTotalSlides(content.items.length);
		setActiveContent(content.items[0]);
	}, []);

	const updateCurrentSlide = (index) => {
		if (currentSlide !== index) {
			setCurrentSlide(index);
			setActiveContent(content.items[index]);
		}
	};

	const galleryContent = content.items
		.filter((content) => content.image.fluid !== null)
		.map((content, index) => {
			return (
				<SlideContainer key={`reasons_to_buy_images_${index}`}>
					<div>
						<img
							src={content.image.fluid.srcWebp}
							srcSet={content.image.fluid.srcSetWebp}
							alt={content.image.alt}
							loading={index <= 2 ? `eager` : `lazy`}
						/>
					</div>
				</SlideContainer>
			);
		});

	return (
		<FlexContainer
			desktopPadding={`0 60px`}
			tabletPadding={`0 35px`}
			mobilePadding={`0 15px`}
			cv={true}
		>
			<TransitionContainer>
				<Row justifyContent="center">
					<Col col={12} md={6} lg={4}>
						<Textbox
							className="title"
							dangerouslySetInnerHTML={{
								__html: content.primary.slideshow_with_text_title.html,
							}}
							margin={`20px 0 10px 0`}
							mobileMargin={`0`}
						/>
						<Textbox
							className="text"
							dangerouslySetInnerHTML={{
								__html: content.primary.slideshow_with_text.html,
							}}
							margin={`0 auto 35px 0`}
							mobileMargin={`5px 0 20px 0`}
						/>
					</Col>
					<Col col={12} md={6} lg={8}>
						<GalleryContainer>
							<Carousel
								showThumbs={false}
								showIndicators={false}
								showStatus={false}
								infiniteLoop={true}
								useKeyboardArrows={true}
								className="gallery-with-text"
								selectedItem={currentSlide}
								onChange={(index) => updateCurrentSlide(index)}
								autoPlay={isMobile ? false : true}
								stopOnHover={false}
								interval={6000}
								swipeable={true}
							>
								{galleryContent}
							</Carousel>

							{activeContent !== null && (
								<Caption>
									{activeContent.image_title.text !== "" && (
										<Textbox
											className="title"
											dangerouslySetInnerHTML={{
												__html: activeContent.image_title.html,
											}}
											margin={`20px 0 10px 0`}
											mobileMargin={`20px 0 5px 0`}
										/>
									)}
									{activeContent.image_text.text !== "" && (
										<Textbox
											className="text"
											dangerouslySetInnerHTML={{
												__html: activeContent.image_text.html,
											}}
											margin={`20px 0 0 0`}
											mobileMargin={`20px 0 0 0`}
										/>
									)}
								</Caption>
							)}
						</GalleryContainer>
					</Col>
				</Row>
			</TransitionContainer>
		</FlexContainer>
	);
};
