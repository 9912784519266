import React, { useState } from "react";
import styled from "styled-components";

const Thumbnail = styled.img`
	z-index: 100;

	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	width: 100%;
	height: 100%;

	object-fit: cover !important;

	opacity: 0;

	&[src] {
		opacity: 1;
	}
`;
//vimeo.com/api/oembed.json?url=https://vimeo.com/335868910&width=1920&height=1080&quality=1080

async function getVimeoThumbnail(video, width, height) {
	let response = await fetch(
		`https://vimeo.com/api/oembed.json?url=https://vimeo.com/${video}&width=${width}&height=${height}`
	);
	let data = await response.json();
	return data;
}

export const VimeoThumbnail = ({ embed_url, width, height }) => {
	const [vimeoThumbnail, setThumbnail] = useState(null);
	let video_id = /^.*((youtu.be\/|vimeo.com\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/,
		match = embed_url.match(video_id);

	let loadingVimeoThumbnail = getVimeoThumbnail(match[7], width, height);

	loadingVimeoThumbnail.then((result) => {
		setThumbnail(result.thumbnail_url);
	});

	if (vimeoThumbnail !== null) {
		return <Thumbnail src={vimeoThumbnail} loading="lazy" />;
	} else {
		return null;
	}
};
