import React from "react";
import styled from "styled-components";
import { Row, Col } from "styled-bootstrap-grid";
import { Link } from "gatsby";

// Components
import { FlexContainer } from "../containers/flex-container";
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";

// Styles
import { tertiary } from "../utils/colors";

const RelatedProductsContainer = styled.div`
	width: 100%;

	& .title {
		margin: 0 0 25px 0;
	}

	& .product {
		& p {
			margin: 10px 0 40px 0;
		}
	}

	& img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
`;

export const RelatedPages = ({ content }) => {
	const pages = content.items
		.filter((item) => item.page !== null)
		.map((item, index) => (
			<Col
				col={6}
				md={6}
				key={`single_related_page_${item.page.document.id}_${index}`}
			>
				<Link to={item.page.url}>
					<div className="product">
						<AspectRatioImageContainer image={null} padding={75}>
							{item.page.document.data.thumbnail.fluid !== null && (
								<img
									srcSet={item.page.document.data.thumbnail.fluid.srcSetWebp}
									src={item.page.document.data.thumbnail.fluid.srcWebp}
									alt={item.page.document.data.thumbnail.alt}
									loading={`lazy`}
								/>
							)}
						</AspectRatioImageContainer>
						<p>{item.page.document.data.title.text}</p>
					</div>
				</Link>
			</Col>
		));

	if (pages.length >= 1) {
		return (
			<FlexContainer
				largeDesktopPadding={`75px 135px 35px 135px`}
				desktopPadding={`75px 135px 35px 135px`}
				tabletPadding={`35px 35px 0 35px`}
				mobilePadding={`35px 15px 0 15px`}
				bgColor={`#f8f8f8`}
				cv={true}
			>
				<RelatedProductsContainer>
					<Row>{pages}</Row>
				</RelatedProductsContainer>
			</FlexContainer>
		);
	} else {
		return null;
	}
};
