import React, { useEffect, useState } from "react";
import { Row, Col } from "styled-bootstrap-grid";

// Styles
import "../styles/plyr.css";

// Utils
import { desktopContentPosition } from "../utils/content-size-position";

// Components
import { FlexContainer } from "../containers/flex-container";

export const VideoWithControls = ({ content, width, height, pageType }) => {
	const [player, setPlayer] = useState(null);

	useEffect(() => {
		if (typeof window !== "undefined" && typeof document !== "undefined") {
			const Plyr = require("plyr");

			setPlayer(
				new Plyr(`#video-with-controls${content.id}`, {
					autoplay: false,
					muted: false,
					loop: { active: false },
					invertTime: false,
					controls: ["current-time", "play", "progress", "fullscreen"],
					hideControls: true,
					youtube: {
						modestbranding: 1,
						playsinline: 1,
						autoplay: 0,
						showinfo: 0,
						controls: 0,
					},
					clickToPlay: true,
				})
			);
		}
	}, []);

	if (content.primary.video.embed_url !== null) {
		return (
			<FlexContainer
				desktopPadding={pageType === `collection` ? `0 60px 0 135px` : `0 60px`}
				tabletPadding={pageType === `collection` ? `0 35px 0 135px` : `0 35px`}
				mobilePadding={`0 15px`}
				cv={true}
			>
				<Row
					justifyContent={desktopContentPosition(
						content.primary.video_position
					)}
				>
					<Col col={12} md={content.primary.video_size}>
						<div
							className="plyr__video-embed"
							id={`video-with-controls${content.id}`}
							dangerouslySetInnerHTML={{
								__html: content.primary.video.html,
							}}
						/>
					</Col>
					<Col col={12} md={content.primary.video_size}>
						<p className="center-text">{content.primary.video.title}</p>
					</Col>
				</Row>
			</FlexContainer>
		);
	} else {
		return null;
	}
};
