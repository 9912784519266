import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "gatsby";

// Utils
import { ConditionalWrapper } from "../utils/conditional-wrapper";

// Components
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";

// Transitions
import { TransitionContainer } from "../containers/transition-container";

const Banner = styled.div`
	width: 100%;
	max-height: 600px;

	z-index: 10;
	overflow: hidden;
`;

const Img = styled.img`
	object-fit: cover;
	object-position: center;

	width: 100%;
	height: 100%;
	max-height: 600px;
`;

const ImageCaption = styled.div``;

export const ImageBanner = ({ content, contentIndex }) => {
	if (content.primary.image.fluid !== null) {
		return (
			<Banner>
				<TransitionContainer>
					<ConditionalWrapper
						condition={content.primary.link.url}
						wrapper={(children) => (
							<Link to={content.primary.link.url}>{children}</Link>
						)}
					>
						<AspectRatioImageContainer image={null} padding={`45`}>
							<Img
								srcSet={content.primary.image.fluid.srcSetWebp}
								src={content.primary.image.fluid.srcWebp}
								loading={`lazy`}
								alt={
									content.primary.link.type !== null
										? `Link to ${content.primary.link.type}`
										: content.primary.image.alt
								}
							/>
						</AspectRatioImageContainer>
						{content.primary.image_credit !== undefined && (
							<>
								{content.primary.image_credit.text !== "" && (
									<ImageCaption
										className="small-text"
										dangerouslySetInnerHTML={{
											__html: content.primary.image_credit.html,
										}}
									/>
								)}
							</>
						)}
					</ConditionalWrapper>
				</TransitionContainer>
			</Banner>
		);
	} else {
		return null;
	}
};
